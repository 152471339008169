import { SagaIterator } from "redux-saga"
import { all, call, delay, fork, put, select, take } from "redux-saga/effects"
import { LOCATION_CHANGE, push } from "connected-react-router"
import { ICanteenDetails, ITodaysMenu, IWeeksMenu, UserLocation } from "shared/Types/appTypes"
import { IErrorResponse, IProtocolError } from "shared/Types/responseTypes"
import { Action, ActionTypes } from "../Actions/actionConstants"

import {
    errorHandlerSagaCreator,
    SagaLogger,
    errorLogger,
    apiRequestSaga,
    apiRequestSagaWithOptions,
} from "shared/Helpers/SagaHelper"
import { selectApiToken } from "shared/Modules/Login/loginSelectors"

import { internetConnectionSaga } from "./internetConnection"
import { IStoreState } from "../Reducers/rootReducer"
import {
    getCanteens as getCanteensAction,
    getTodaysMenu,
    getWeeksMenu,
    setTodaysMenu,
    setWeeksMenu,
    setTakeawayOffers,
    setTakeawayCanteens,
    setProductFavorites,
    setCanteens,
} from "../Actions/canteenActions"
import { getCanteens, GetCanteensResponse } from "../Api/GetCanteens"
import { getTodaysMenu as getTodaysMenuApi } from "../Api/GetTodaysMenu"
import { getWeeksMenu as getWeeksMenuApi } from "../Api/GetWeeksMenu"
import { getUpcomingMeetings } from "shared/Modules/Meeting/orderActions"
import { getAvailableRooms, ResourceAvailabilityResponse } from "../Api/GetAvailableRooms"
import { setAvailableRooms, clearAvailableRooms } from "../Actions/availbleRoomsActions"
import { getResourceFilters, ResourceFiltersResponse } from "../Api/GetResourceFilters"
import { getResourceFilters as getResourceFiltersAction, setResourceFilters } from "../Actions/resourceFiltersActions"
import moment from "moment"
import { getNotificationCount } from "shared/Modules/Notification/notificationActions"
import { getTodaysOrderCount } from "shared/Modules/Today/todayActions"
import { getPredefinedMessages, PredefinedMessagesResponse } from "../Api/GetPredefinedMessages"
import { getPredefinedMessages as getPredefinedMessagesAction } from "../Actions/getPredefinedMessages"
import { setPredefinedMessages } from "mobile/Actions/getPredefinedMessages"
import { clearShoppingCart, addResourceToShoppingCart } from "mobile/Actions/shoppingCartActions"
import { setResourceFilterValues, resetResourceFilterValues } from "mobile/Actions/resourceFilterValuesActions"
import cordovaSaga from "shared/Modules/Cordova/cordovaSagas"
import errorSaga from "shared/Modules/Error/errorSagas"
import loginSaga from "shared/Modules/Login/loginSagas"
import orderDetailsSaga from "shared/Modules/OrderDetails/orderDetailsSagas"
import localizationSaga, { updateTranslationsSaga } from "shared/Modules/Localization/localizationSagas"
import userSaga, { updateUser } from "shared/Modules/User/userSagas"
import propertySaga, { updateProperties } from "shared/Modules/Properties/propertySagas"
import { getProperties } from "shared/Modules/Properties/propertyActions"
import merchantConfigSaga, { updateMerchantConfig } from "shared/Modules/MerchantConfig/merchantConfigSagas"
import { getMerchantConfig } from "shared/Modules/MerchantConfig/merchantConfigActions"
import { getNews, NewsActionTypes } from "shared/Modules/News/newsActions"
import newsSaga, { updateAllNewsCategories } from "shared/Modules/News/newsSagas"
import meetingSaga, { updateUpcomingOrdersSaga } from "shared/Modules/Meeting/meetingSagas"
import notificationSaga from "shared/Modules/Notification/notificationSagas"
import todaySaga from "shared/Modules/Today/todaySagas"
import { getTakeawayOffers, ITakeawayOfferWrapper } from "mobile/Api/GetTakeawayOffers"
import { getTakeawayShops } from "mobile/Api/GetTakeawayShops"
import { getProductFavorites } from "mobile/Api/GetProductFavorites"
import { getProductFavorites as getProductFavoritesAction } from "mobile/Actions/canteenActions"
import { getUser, IValidUser, UserActionTypes } from "shared/Modules/User/userActions"
import { selectUserCanteenId, selectUserLocation, selectUserLocationId } from "shared/Modules/User/userSelectors"
import { selectConfigRefreshContentInMillis } from "shared/Modules/Properties/propertySelectors"
import { IFoodOrderProduct } from "shared/Modules/Product/productTypes"
import { toSelection } from "shared/Modules/Location/locationLib"
import { ISetEditOrder, MeetingActionTypes } from "shared/Modules/Meeting/meetingActions"
import { MeetingModalActionTypes } from "shared/Modules/Meeting/meetingModalActions"
import { updateNotificationCount } from "shared/Modules/Notification/notificationSagas"
import { updateTodaysOrderCount } from "shared/Modules/Today/todaySagas"
import userCardsSaga, { updateMeCards, updateHomeCards } from "shared/Modules/UserCards/userCardsSagas"

// The root saga is responsible for starting (forking) all other sagas
export function* rootSaga(): SagaIterator {
    yield all([
        fork(cordovaSaga),
        fork(errorSaga),
        fork(loginSaga),
        fork(orderDetailsSaga),
        fork(userSaga),
        fork(userCardsSaga),
        fork(propertySaga),
        fork(merchantConfigSaga),
        fork(localizationSaga),
        fork(newsSaga),
        fork(meetingSaga),
        fork(notificationSaga),
        fork(todaySaga),
        fork(errorHandlerSagaCreator, errorLogger, editMeetingSaga),
        fork(errorHandlerSagaCreator, errorLogger, internetConnectionSaga),
        fork(errorHandlerSagaCreator, errorLogger, getAvailableRoomsSaga),
        fork(errorHandlerSagaCreator, errorLogger, getResourceFiltersSaga),
        fork(errorHandlerSagaCreator, errorLogger, getCanteensSaga),
        fork(errorHandlerSagaCreator, errorLogger, getTodaysMenuSaga),
        fork(errorHandlerSagaCreator, errorLogger, getWeeksMenuSaga),
        fork(errorHandlerSagaCreator, errorLogger, getPredefinedMessagesSaga),
        fork(errorHandlerSagaCreator, errorLogger, getProductFavoritesSaga),
        fork(errorHandlerSagaCreator, errorLogger, periodicReloadContentSaga),
        fork(errorHandlerSagaCreator, errorLogger, refreshContentOnLocationChangeSaga, console.log),
        fork(errorHandlerSagaCreator, errorLogger, refreshContentOnEnterSaga, console.log),
    ])
}

function* editMeetingSaga(): SagaIterator {
    while (true) {
        const { order, orderDetails }: ISetEditOrder = yield take(MeetingActionTypes.SET_EDIT_ORDER)

        const meetingDuration = orderDetails?.meetingDetails?.meetingDuration ?? 60
        const serviceCenterIds: any = {}
        const resourceTypeIds: any = {}

        yield put(clearShoppingCart())

        if (orderDetails && orderDetails.bookingDetails && orderDetails.bookingDetails.resources) {
            for (const bookingResource of orderDetails.bookingDetails.resources) {
                resourceTypeIds[bookingResource.resourceTypeId] = true
                serviceCenterIds[bookingResource.serviceCenterId] = true
                yield put(
                    addResourceToShoppingCart({
                        selected: true,
                        bookingSuggestion: {
                            endDatetime: orderDetails.meetingDetails ? orderDetails.meetingDetails.startDateTime : "",
                            startDatetime: orderDetails.meetingDetails ? orderDetails.meetingDetails.endDateTime : "",
                        },
                        resource: {
                            id: bookingResource.id,
                            name: bookingResource.name,
                        },
                    })
                )
            }
        }

        if (order) {
            yield put(
                setResourceFilterValues({
                    resourceTypeIds,
                    serviceCenterIds,
                    meetingDuration,
                    startDateTime: moment(order.meetingDetails?.startDateTime).format("YYYY-MM-DD HH:mm"),
                })
            )
        } else {
            yield put(resetResourceFilterValues())
        }

        yield put(clearAvailableRooms())
        yield put(push("/room-finder"))
    }
}

export function getResourceStartDateTime(state: IStoreState) {
    console.log("resourceFilterValues", state.resourceFilterValues.values)

    if (state.resourceFilterValues.values.startDateTime) {
        return state.resourceFilterValues.values.startDateTime.format("YYYY-MM-DD HH:mm")
    }

    return moment().format("YYYY-MM-DD HH:mm")
}

export function getResourceMeetingDuration(state: IStoreState) {
    if (state.resourceFilterValues.values.meetingDuration) {
        return state.resourceFilterValues.values.meetingDuration
    }

    return 60
}

export function getResourceEndDateTime(state: IStoreState) {
    return moment(getResourceStartDateTime(state))
        .add(getResourceMeetingDuration(state), "minutes")
        .format("YYYY-MM-DD HH:mm")
}

export function getSearchOptions(state: IStoreState) {
    const filters = state.resourceFilters.filters ? state.resourceFilters.filters : null
    const values = state.resourceFilterValues ? state.resourceFilterValues.values : null

    if (!filters || !values) {
        return {}
    }

    // const editOrder = state.editOrder;
    const searchOptions = {
        startDateTime: moment().format("YYYY-MM-DD HH:mm:00"),
        // 'resourceIds': editOrder.resourceIds,
    }

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < filters.length; i++) {
        const filterElements = filters[i].filterElements

        // tslint:disable-next-line:prefer-for-of
        for (let x = 0; x < filterElements.length; x++) {
            const filterElement = filterElements[x]

            switch (filterElement.elementType) {
                case "TimeSlider":
                    if (typeof values[filterElement.identifier] !== "undefined") {
                        searchOptions[filterElement.identifier] = values[filterElement.identifier]
                    } else {
                        searchOptions[filterElement.identifier] = 60
                    }
                    break

                case "PlusMinusButton":
                    if (typeof values[filterElement.identifier] !== "undefined") {
                        searchOptions[filterElement.identifier] = values[filterElement.identifier]
                    } else {
                        searchOptions[filterElement.identifier] = 0
                    }
                    break

                case "CheckBox":
                case "MultiSelect":
                    if (typeof values[filterElement.identifier] !== "undefined") {
                        searchOptions[filterElement.identifier] = []
                        Object.keys(values[filterElement.identifier]).forEach((key: any) => {
                            if (values[filterElement.identifier] && values[filterElement.identifier][key]) {
                                searchOptions[filterElement.identifier].push(key)
                            }
                        })
                    }
                    break

                case "DateTimePicker":
                    if (typeof values[filterElement.identifier] !== "undefined") {
                        searchOptions[filterElement.identifier] = moment(values[filterElement.identifier]).format(
                            "YYYY-MM-DD HH:mm:00"
                        )
                    }
                    break

                default:
                    console.warn("Filter element type [" + filterElement.elementType + "] not supported!")
            }
        }
    }

    return searchOptions
}

export function* getAvailableRoomsSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_AVAILABLE_ROOMS)
        yield call(updateAvailableRooms)
    }
}

export function* updateAvailableRooms(isBackgroundRequest: boolean = false): SagaIterator {
    const token = yield select(selectApiToken)
    const searchOptions = yield select(getSearchOptions)
    if (token) {
        const response = yield* apiRequestSaga<ResourceAvailabilityResponse>(
            getAvailableRooms,
            {},
            isBackgroundRequest,
            token,
            searchOptions
        )
        if (response) yield put(setAvailableRooms(response.resourceTypes))
    }
}

export function* getPredefinedMessagesSaga(): SagaIterator {
    while (true) {
        const isBackgroundRequest = false
        const { resourceId } = yield take(ActionTypes.GET_PREDEFINED_MESSAGES)
        const token = yield select(selectApiToken)
        if (token) {
            const response = yield* apiRequestSaga<PredefinedMessagesResponse>(
                getPredefinedMessages,
                {},
                isBackgroundRequest,
                token,
                resourceId
            )
            if (response) yield put(setPredefinedMessages(response.messages))
        }
    }
}

export function* getResourceFiltersSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_RESOURCE_FILTERS)
        yield call(updateResourceFilters)
    }
}

export function* updateResourceFilters(isBackgroundRequest = false): SagaIterator {
    const token = yield select(selectApiToken)
    if (token) {
        const response = yield* apiRequestSaga<ResourceFiltersResponse>(
            getResourceFilters,
            {},
            isBackgroundRequest,
            token
        )
        if (response) yield put(setResourceFilters(response.filterGroups))
    }
}

export function* getCanteensSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_CANTEENS)
        yield call(updateCanteens)
    }
}

export function* updateCanteens(isBackgroundRequest = false): SagaIterator {
    const token: string | null = yield select(selectApiToken)

    if (token) {
        const responseData = yield* apiRequestSaga<GetCanteensResponse>(getCanteens, {}, isBackgroundRequest, token)
        if (responseData) yield put(setCanteens(responseData.kitchens))
    }
}

export function* updateTakeawayOffers(isBackgroundRequest = false): SagaIterator {
    const token = yield select(selectApiToken)
    if (token) {
        const response = yield* apiRequestSaga<ITakeawayOfferWrapper>(
            getTakeawayOffers,
            {},
            isBackgroundRequest,
            token,
            20
        )

        if (response) yield put(setTakeawayOffers(response.menues?.[0].products))
    }
}

export function* updateTakeawayCanteens(isBackgroundRequest = false): SagaIterator {
    const token = yield select(selectApiToken)
    if (token) {
        const response = yield* apiRequestSaga<{ kitchens: ICanteenDetails[] }>(
            getTakeawayShops,
            {},
            isBackgroundRequest,
            token,
            20
        )

        if (response) yield put(setTakeawayCanteens(response.kitchens))
    }
}

export function* getProductFavoritesSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_PRODUCT_FAVORITES)
        yield call(updateProductFavorites)
    }
}

export function* updateProductFavorites(isBackgroundRequest = false): SagaIterator {
    const token = yield select(selectApiToken)
    if (token) {
        const response = yield* apiRequestSaga<{ products: IFoodOrderProduct[] }>(
            getProductFavorites,
            {},
            isBackgroundRequest,
            token,
            10
        )

        if (response) yield put(setProductFavorites(response.products))
    }
}

export function* getTodaysMenuSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_TODAYS_MENU)
        yield call(updateTodaysMenu)
    }
}

export function* updateTodaysMenu(isBackgroundRequest = false): SagaIterator {
    function* notFoundHandler(response: IErrorResponse | IProtocolError) {
        if (response.responseCode === 404) {
            // Canteen is gone, don't show menu
            yield put(setTodaysMenu({ menues: [] }))
            return true
        }
        return false
    }

    const token: string | null = yield select(selectApiToken)
    const userCanteenId: number | undefined = yield select(selectUserCanteenId)

    if (token && userCanteenId) {
        const responseData: ITodaysMenu | null = yield* apiRequestSagaWithOptions(
            getTodaysMenuApi,
            {
                responseHandler: {
                    error: notFoundHandler,
                    protocolError: notFoundHandler,
                },
                isBackgroundRequest,
            },
            token,
            userCanteenId
        )

        if (responseData) yield put(setTodaysMenu(responseData))
    }
}

export function* getWeeksMenuSaga(): SagaIterator {
    while (true) {
        yield take(ActionTypes.GET_WEEKS_MENU)
        yield call(updateWeeksMenu)
    }
}

export function* updateWeeksMenu(isBackgroundRequest = false): SagaIterator {
    function* notFoundHandler(response: IErrorResponse | IProtocolError) {
        if (response.responseCode === 404) {
            // Canteen is gone, don't show menu
            yield put(setWeeksMenu({ menues: [] }))
            return true
        }
        return false
    }

    const token: string | null = yield select(selectApiToken)
    const userCanteenId: number | undefined = yield select(selectUserCanteenId)

    if (token && userCanteenId) {
        const responseData: IWeeksMenu | null = yield* apiRequestSagaWithOptions(
            getWeeksMenuApi,
            {
                responseHandler: {
                    error: notFoundHandler,
                    protocolError: notFoundHandler,
                },
                isBackgroundRequest,
            },
            token,
            userCanteenId
        )

        if (responseData) yield put(setWeeksMenu(responseData))
    }
}

export function getOrderDetailsModalOrderId(state: IStoreState) {
    const meetingDetails = state.meetingDetailsModal
    return meetingDetails.open ? meetingDetails.order : null
}

enum UpdateReason {
    PeriodicRefresh,
    RefreshOnEnter,
    LocationChanged,
}

function* reloadContentSaga(updateReason: UpdateReason) {
    if (updateReason === UpdateReason.PeriodicRefresh) {
        const isBackgroundRequest = true
        yield call(updateUser, isBackgroundRequest)
        yield call(updateProperties, isBackgroundRequest)
        yield call(updateMerchantConfig, isBackgroundRequest)
        yield call(updateCanteens, isBackgroundRequest)
        yield call(updateTodaysMenu, isBackgroundRequest)
        yield call(updateWeeksMenu, isBackgroundRequest)
        yield call(updateUpcomingOrdersSaga, isBackgroundRequest)
        yield call(updateAllNewsCategories, isBackgroundRequest)
        yield call(updateTakeawayOffers, isBackgroundRequest)
        yield call(updateHomeCards, isBackgroundRequest)
        yield call(updateTodaysOrderCount, isBackgroundRequest)
        yield call(updateNotificationCount, isBackgroundRequest)
        yield call(updateTakeawayCanteens, isBackgroundRequest)
        yield call(updateProductFavorites, isBackgroundRequest)
        //const locale = yield select(getLocale);
        //yield call(updateTranslationsSaga, locale, isBackgroundRequest);
    } else if (updateReason === UpdateReason.RefreshOnEnter || updateReason === UpdateReason.LocationChanged) {
        if (updateReason !== UpdateReason.LocationChanged) {
            yield put(getUser())
            yield put(getProperties())
            yield put(getNotificationCount())
            yield put(getTodaysOrderCount())
            yield put(getUpcomingMeetings())
        }
        yield put(getMerchantConfig())
        yield put(getCanteensAction())
        yield put(getTodaysMenu())
        yield put(getWeeksMenu())
        yield put(getNews())
        yield call(updateTakeawayOffers)
        yield call(updateHomeCards)
        yield call(updateTakeawayCanteens)
        yield put(getProductFavoritesAction())
    }
}

function* periodicReloadContentSaga() {
    while (true) {
        console.log("Reloading content in background")
        yield* reloadContentSaga(UpdateReason.PeriodicRefresh)
        const time: number = yield select(selectConfigRefreshContentInMillis)
        console.log(`Sleeping [${time}ms]`)
        yield delay(time)
    }
}

function* refreshContentOnLocationChangeSaga(logger: SagaLogger): SagaIterator {
    while (true) {
        const oldLocation: UserLocation | undefined = yield select(selectUserLocation)
        const oldSelection = toSelection(oldLocation)
        const action: IValidUser = yield take(UserActionTypes.SET_USER)
        const newLocation: UserLocation | undefined = selectUserLocation(action)
        const newSelection = toSelection(newLocation)
        if (
            (newSelection?.locationId !== undefined && newSelection.locationId !== oldSelection?.locationId) ||
            (newSelection?.kitchenId !== undefined && newSelection.kitchenId !== oldSelection?.kitchenId)
        ) {
            // We need to update some content if location changes since that content depends on location
            logger(
                `Update content due to user location update [old location id: ${oldSelection?.locationId}, old kitchen id: ${oldSelection?.kitchenId}, new location id: ${newSelection.locationId}, new kitchen id: ${newSelection.kitchenId}]`
            )
            yield* reloadContentSaga(UpdateReason.LocationChanged)
        }
    }
}

function* refreshContentOnEnterSaga(logger: SagaLogger) {
    while (true) {
        // Listen for navigation changes and when modals that fetch data open (since they don't cause navigation)
        logger(`Listening for navigation actions...`)
        const action: Action = yield take([
            LOCATION_CHANGE,
            ActionTypes.OPEN_RESOURCE_FILTERS_MODAL,
            ActionTypes.OPEN_WEEK_MENU_MODAL,
            NewsActionTypes.OPEN_ALL_NEWS_MODAL,
            MeetingModalActionTypes.OPEN_REQUEST_ASSISTANCE_MODAL,
        ])
        switch (action.type) {
            case ActionTypes.OPEN_RESOURCE_FILTERS_MODAL:
                logger("Opened resource filter modal [refresh filters]")
                yield put(getResourceFiltersAction())
                break
            case ActionTypes.OPEN_WEEK_MENU_MODAL:
                logger("Opened week menu modal [refresh content]")
                yield put(getWeeksMenu())
                break
            case NewsActionTypes.OPEN_ALL_NEWS_MODAL:
                logger("Opened all news modal [refresh content]")
                yield put(getNews())
                break
            case MeetingModalActionTypes.OPEN_REQUEST_ASSISTANCE_MODAL:
                logger("Opened request assistance modal [loading messages]")
                if (
                    action.meetingDetails &&
                    action.meetingDetails.bookingDetails &&
                    action.meetingDetails.bookingDetails.resources
                ) {
                    yield put(getPredefinedMessagesAction(action.meetingDetails.bookingDetails.resources[0].id))
                }
                break
            case LOCATION_CHANGE:
                const {
                    payload: {
                        location: { pathname },
                    },
                } = action
                switch (pathname) {
                    case "/":
                        // NOTE: We dispatch actions instead of calling the sagas directly
                        // By going via the Redux store we can follow the action in Redux DevTools browser plugin
                        // This could also be used to avoid splitting all the load sagas in two as they are above
                        logger(`Navigated to '/' [refresh landing page content]`)
                        yield* reloadContentSaga(UpdateReason.RefreshOnEnter)
                        break
                    case "/me":
                        logger("Navigated to '/me' [refresh user cards for page]")
                        yield call(updateMeCards)
                        break
                    // TODO: Add other locations that need background refresh when entering
                    default:
                        logger(`No handling defined for path '${pathname}' [not refreshing content]`)
                }
                break
            default:
                logger(`Action type not recognized: '${action.type}' [not refreshing content]`)
                break
        }
    }
}
