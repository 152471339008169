import { IMerchantConfig } from "./merchantConfigTypes"

export enum MerchantConfigActionTypes {
    GET_MERCHANT_CONFIG = "GET_MERCHANT_CONFIG",
    SET_MERCHANT_CONFIG = "SET_MERCHANT_CONFIG",
}

export interface IGetMerchantConfig {
    readonly type: MerchantConfigActionTypes.GET_MERCHANT_CONFIG
}

export interface ISetMerchantConfig {
    readonly type: MerchantConfigActionTypes.SET_MERCHANT_CONFIG
    readonly merchantConfig: IMerchantConfig
}

export const getMerchantConfig = (): IGetMerchantConfig => ({
    type: MerchantConfigActionTypes.GET_MERCHANT_CONFIG,
})

export const setMerchantConfig = (merchantConfig: IMerchantConfig): ISetMerchantConfig => ({
    type: MerchantConfigActionTypes.SET_MERCHANT_CONFIG,
    merchantConfig,
})

export type MerchantConfigAction = IGetMerchantConfig | ISetMerchantConfig
