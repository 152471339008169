export enum MerchantSettingsDetailsName {
    SHOW_WEEK_MENU = "SHOW_WEEK_MENU",
}

export type MerchantSettingsDetails = Readonly<{
    name: MerchantSettingsDetailsName
    value: string
}>

export type MerchantSettings = Readonly<{
    merchantSettings?: ReadonlyArray<MerchantSettingsDetails>
}>

export interface IMerchantConfig {
    readonly config: MerchantSettings
}
