import { CurrentEnvironment } from "shared/Modules/Environment/envTypes"
import { ApiResponse } from "shared/Types/responseTypes"
import { IMerchantConfig } from "./merchantConfigTypes"
import { get } from "shared/Helpers/http"

export const fetchMerchantConfig = (
    token: string,
    merchantId: number,
    environment: CurrentEnvironment
): Promise<ApiResponse<IMerchantConfig>> => {
    return get(`/me/merchants/${merchantId}/config`, {
        token,
        environment,
    })
}
