import clsx from "clsx"
import React, { CSSProperties, PropsWithChildren } from "react"
import { makeStyles, Typography } from "@material-ui/core"
import { StrictOmit } from "shared/Types/helperTypes"

type HomeCardTitleProps = Readonly<
    PropsWithChildren<{
        className?: string
        marginTop?: CSSProperties["marginTop"]
        marginBottom?: CSSProperties["marginBottom"]
    }>
>

type StyleProps = StrictOmit<HomeCardTitleProps, "children" | "className">

const useStyles = makeStyles(() => ({
    homeCardTitle: (props: StyleProps) => ({
        color: "#334155",
        fontSize: ".95rem",
        lineHeight: 1.2,
        fontWeight: 900,
        marginTop: props.marginTop,
        marginBottom: props.marginBottom,
    }),
}))

export function HomeCardTitle(props: HomeCardTitleProps) {
    const classes = useStyles(props)

    return (
        <Typography variant="subtitle2" className={clsx(props.className, classes.homeCardTitle)}>
            {props.children}
        </Typography>
    )
}
