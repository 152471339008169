import { Reducer } from "redux"
import { IMerchantConfig, MerchantSettingsDetailsName } from "./merchantConfigTypes"
import { MerchantConfigActionTypes } from "./merchantConfigActions"
import { UserActionTypes } from "shared/Modules/User/userActions"
import { SharedAction } from "shared/Types/actionTypes"

export const initialState: IMerchantConfig = {
    config: {
        merchantSettings: [
            {
                name: MerchantSettingsDetailsName.SHOW_WEEK_MENU,
                value: "true",
            },
        ],
    },
}

const merchantConfigReducer: Reducer<IMerchantConfig, SharedAction> = (state = initialState, action) => {
    switch (action.type) {
        case MerchantConfigActionTypes.SET_MERCHANT_CONFIG:
            return {
                ...state,
                ...action.merchantConfig,
            }
        case UserActionTypes.LOGOUT_USER:
            return initialState
        default:
            return state
    }
}

export default merchantConfigReducer
