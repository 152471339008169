import { Reducer } from "redux"
import { IMenu } from "shared/Types/appTypes"
import { ActionTypes, Action } from "mobile/Actions/actionConstants"

export const initialState = {
    menues: null as IMenu[] | null,
    externalMenuPlanning: null as { readonly url: string } | null,
}

const propertyReducer: Reducer<
    { menues: IMenu[] | null; externalMenuPlanning: { readonly url: string } | null },
    Action
> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_WEEKS_MENU:
            const { weeksMenu } = action

            if ("menues" in weeksMenu) {
                return {
                    ...state,
                    menues: weeksMenu.menues,
                    externalMenuPlanning: null,
                }
            } else if ("externalMenuPlanning" in weeksMenu) {
                return {
                    ...state,
                    menues: null,
                    externalMenuPlanning: weeksMenu.externalMenuPlanning,
                }
            }
        default:
            return state
    }
}

export default propertyReducer
