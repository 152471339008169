import clsx from "clsx"
import React, { CSSProperties, PropsWithChildren } from "react"
import { makeStyles, Typography } from "@material-ui/core"
import { StrictOmit } from "shared/Types/helperTypes"

type HomeCardDescriptionProps = Readonly<
    PropsWithChildren<{
        className?: string
        marginTop?: CSSProperties["marginTop"]
        marginBottom?: CSSProperties["marginBottom"]
    }>
>

type StyleProps = StrictOmit<HomeCardDescriptionProps, "children" | "className">

const useStyles = makeStyles(() => ({
    homeCardDescription: (props: StyleProps) => ({
        color: "#64748B",
        marginTop: props.marginTop,
        marginBottom: props.marginBottom,
    }),
}))

export function HomeCardDescription(props: HomeCardDescriptionProps) {
    const classes = useStyles(props)

    return (
        <Typography variant="body2" className={clsx(props.className, classes.homeCardDescription)}>
            {props.children}
        </Typography>
    )
}
