import { createStyles, Grid, Link, makeStyles, Theme, Toolbar, Typography, useTheme } from "@material-ui/core"
import React, { useCallback, useState } from "react"
import { connect, MapDispatchToPropsFunction, MapStateToProps, useSelector } from "react-redux"
import AppBar from "shared/Components/Skeleton/AppBar"
import Screen from "shared/Components/Skeleton/Screen"
import { Roboto } from "shared/theme"
import { ICanteen, ICanteenDetails, INews, IOrder, WebshopType } from "shared/Types/appTypes"
import { IOpenAllNewsModal, openAllNewsModal } from "shared/Modules/News/newsActions"
import { getProductFavorites, IGetProductFavorites } from "../../Actions/canteenActions"
import { IOpenMeetingDetailsModal, openMeetingDetailsModal } from "../../Actions/meetingDetailsModalActions"
import { IOpenMeetingOptionsModal, openMeetingOptionsModal } from "../../Actions/meetingOptionsModalActions"
import { IOpenNewsDetailsModal, openNewsDetailsModal } from "shared/Modules/News/newsActions"
import { setFoodOrderCartCache } from "shared/Modules/Meeting/orderActions"
import CardCarousel from "../../Components/CardCarousel"
import Heading from "shared/Components/Skeleton/Heading"
import { Section } from "shared/Components/Skeleton/Section"
import ResourceCarousel from "../../Components/ResourceCarousel"
import { IStoreState } from "../../Reducers/rootReducer"
import FeaturedProductModal from "../../Components/FeaturedProductModal"
import ShowAllTakeawayOffersModal from "mobile/Components/ShowAllTakeawayOffersModal"
import ShowAllProductFavoritesModal from "mobile/Components/ShowAllProductFavoritesModal"
import ChooseLocationDialog from "shared/Modules/Location/Screens/ChooseLocationDialog"
import { INewsState } from "shared/Modules/News/newsReducer"
import UpcomingMeetingCard from "./components/UpcomingMeetingCard"
import ProductFavoritesCarousel from "./components/ProductFavoritesCarousel"
import { ActionSheetButtonItem } from "shared/Components/Dialog/Options/ActionSheetModalContent"
import OptionsDialog from "shared/Components/Dialog/Options/OptionsDialog"
import removeProductFromFavorites from "../../Api/RemoveProductToFavorites"
import { useToken } from "shared/Modules/Login/useToken"
import MyMeetingsDialog from "../Me/components/MyMeetingsDialog"
import WelcomeSection from "./components/WelcomeSection"
import { getUserWelcomeSection } from "../../Api/GetUserWelcomeSection"
import { useOptionalQuery } from "shared/Modules/Query/useQuery"
import { isEmpty } from "lodash"
import { SafeArea } from "shared/Components/Skeleton/SafeArea"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-light-svg-icons/faChevronDown"
import { faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons/faMapMarkerAlt"
import { useApiCall } from "shared/Modules/Query/useApiCall"
import { ErrorDisplay } from "shared/Modules/Error/errorTypes"
import { WellknownHeight } from "shared/Helpers/constants"
import { DateTime } from "luxon"
import { useAppNavigation } from "shared/hooks/useAppNavigation"
import { IFoodOrderProduct } from "shared/Modules/Product/productTypes"
import BottomNavBar from "../../Components/Skeleton/BottomNavBar"
import { useExternalLinks } from "shared/Modules/Cordova/hooks/useAppLinks"
import { Logger } from "shared/Helpers/logging"
import { LoginStatus } from "shared/Modules/Login/loginTypes"
import { selectUserCanteenId, selectUserLocationId, selectUserLocationName } from "shared/Modules/User/userSelectors"
import { setEditOrder } from "shared/Modules/Meeting/meetingActions"
import { UserPrompts } from "shared/Modules/User/Components/UserPrompts"
import { UserCardPages } from "shared/Modules/UserCards/userCardsTypes"
import { UserCards } from "shared/Modules/UserCards/Components/UserCards"
import ResourceCard from "mobile/Components/ResourceCard"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import MenuOfTheWeekSection from "./components/MenuOfTheWeekSection"

export interface IOwnProps {}

interface IStateProps {
    canteens: ICanteen[] | null
    upcomingMeetings: IOrder[] | null
    news: INewsState
    myLocation: string | undefined
    userUid: string | undefined
    takeawayOffers: IFoodOrderProduct[] | null
    takeawayCanteens: ICanteenDetails[] | null
    productFavorites: IFoodOrderProduct[] | null
    resourceBookingEnabled: boolean
}

interface IDispatchProps {
    showMeetingOptions: (order: IOrder) => IOpenMeetingOptionsModal
    showMeetingDetails: (order: IOrder) => IOpenMeetingDetailsModal
    showNewsDetailsModal: (resource: INews) => IOpenNewsDetailsModal
    showAllNews: (category: keyof INewsState) => IOpenAllNewsModal
    goToRoomFinder: () => any
    setFoodOrderCartCache: (product: IFoodOrderProduct) => void
    getProductFavorites: () => IGetProductFavorites
}

interface ISelectedDialog {
    type: SelectedDialog
    productId?: number
}

type Props = IOwnProps & IStateProps & IDispatchProps

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        canteenCardContent: {
            paddingBottom: "6px !important",
        },
        canteenName: {
            color: "#505050",
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 900,
            letterSpacing: 0,
            marginTop: 0,
        },
        canteenDetailsRow: {
            color: "#505050",
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            letterSpacing: -0.25,
            position: "relative",
        },
        canteenDetailsIcon: {
            marginRight: 8,
            color: "#aaaaaa",
            position: "absolute",
        },
        canteenDetailsText: {
            width: "calc(100% - 20px)",
            display: "inline-block",
            marginLeft: 20,
        },
        canteenDetailsLink: {
            color: "#0090FF",
            textDecoration: "none",
        },
        noPlannedMeetings: {
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 400,
            letterSpacing: 0,
            lineHeight: "19px",
        },
        topBarContainer: {
            paddingBottom: `calc(${WellknownHeight.PRIMARY_TOP_BAR}px + 0.75rem)`,
        },
        topBar: {
            color: theme.palette.text.primary,
        },
        boldText: {
            fontWeight: "bold",
        },
        locationLine: {
            color: theme.palette.primary.main,
        },
        locationLineLinkContainer: {
            flexGrow: 1,
        },
        locationName: {
            flexShrink: 1,
            maxWidth: "calc(100% - 42px)",
        },
        locationLineMapMarker: {
            height: "1.5em",
            width: "auto !important",
            marginBottom: theme.spacing(1),
        },
        overflowEllipsis: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
        },
        list: {
            gap: "1.5rem",
        },
    })
)

enum SelectedDialog {
    SETTINGS,
}

const Home = (props: Props) => {
    const {
        upcomingMeetings,
        news,
        myLocation,
        showMeetingOptions,
        showMeetingDetails,
        showNewsDetailsModal,
        showAllNews,
        goToRoomFinder,
        takeawayOffers,
        takeawayCanteens,
        productFavorites,
        setFoodOrderCartCache,
    } = props
    const theme = useTheme()
    const classes = useStyles()
    const token = useToken()
    const { goToWebshop, goToCheckout } = useAppNavigation()
    const openExternalLink = useExternalLinks()
    const [isLocationDialogOpen, setIsLocationDialogOpen] = useState(false)
    const [featuredProductModal, setFeaturedProductModal] = useState<IFoodOrderProduct>()
    const [displayAllTakeawayOffers, setDisplayAllTakeawayOffers] = useState(false)
    const [displayAllFavorites, setDisplayAllFavorites] = useState(false)
    const [selectedDialog, setSelectedDialog] = useState<ISelectedDialog>()
    const [isMyMeetingsOpen, setIsMyMeetingsOpen] = useState(false)

    const userLocationId = useSelector(selectUserLocationId)
    const userKitchenId = useSelector(selectUserCanteenId)

    const { response: welcomeSection } = useOptionalQuery(
        () => getUserWelcomeSection(token),
        [token, userLocationId, userKitchenId]
    )
    const {
        loading: removingFavorite,
        callForAction: callRemoveFavorite,
        handleCallError,
    } = useApiCall(removeProductFromFavorites, { errorDisplay: ErrorDisplay.Snackbar })

    const logger = new Logger("home")

    function handleCloseDialog() {
        setSelectedDialog(undefined)
    }

    function handleMyMeetings() {
        setIsMyMeetingsOpen((prev) => !prev)
    }

    function openRemoveFavoriteDialog(e: any, productId: number) {
        e.stopPropagation()
        setSelectedDialog({ type: SelectedDialog.SETTINGS, productId: productId })
    }

    function removeFromFavorite(id?: number) {
        setSelectedDialog(undefined)
        if (!id) return
        if (!productFavorites) return
        const details = productFavorites.find((product) => product.id === id)?.userDetails
        if (details && "favoriteType" in details) {
            const type = details.favoriteType
            callRemoveFavorite(id, type, token)
                .then(() => props.getProductFavorites())
                .catch((e) => handleCallError(e, "removing favorite"))
        }
    }

    function onFeaturedProductModalDateClick(date: string, kitchenId: number, product?: IFoodOrderProduct) {
        const parsedDate = DateTime.fromISO(date)
        goToWebshop(kitchenId, product?.webshop?.type ?? WebshopType.TAKE_AWAY, parsedDate)
        if (product) setFoodOrderCartCache(product)
        setFeaturedProductModal(undefined)
    }

    const handleTakeawayOfferClick = useCallback(
        (product: IFoodOrderProduct) => {
            setDisplayAllTakeawayOffers(false)
            if (product.deliveryDates !== undefined && product.deliveryDates.length > 0)
                setFeaturedProductModal(product)
            else if (product.kitchen) {
                goToWebshop(product.kitchen.id, product.webshop?.type ?? WebshopType.TAKE_AWAY)
                setFoodOrderCartCache(product)
            }
        },
        [setDisplayAllTakeawayOffers, setFeaturedProductModal, goToWebshop]
    )

    const onTakeawayOfferClick = useCallback(
        (product: any) => handleTakeawayOfferClick(product as IFoodOrderProduct),
        [handleTakeawayOfferClick]
    )
    const onTakeawayOfferRightOptionClick = useCallback(
        () => setDisplayAllTakeawayOffers(true),
        [setDisplayAllTakeawayOffers]
    )

    const onTakeawayShopClick = useCallback(
        (canteen: ICanteenDetails) => {
            goToWebshop(canteen.id, canteen.webshop?.type ?? WebshopType.TAKE_AWAY)
        },
        [goToWebshop]
    )

    const onProductFavoriteClick = useCallback(
        (product: IFoodOrderProduct) => {
            if (product.userDetails && "favoriteId" in product.userDetails && product.kitchen) {
                setFoodOrderCartCache(product)
                goToCheckout(product.kitchen.id, product.userDetails.favoriteType)
            }
        },
        [setFoodOrderCartCache, goToCheckout]
    )

    const onProductFavoriteRightOptionClick = useCallback(() => setDisplayAllFavorites(true), [setDisplayAllFavorites])

    const onNewsStoryClick = useCallback(
        (resource: any) => {
            const story = resource as INews
            if (story.external && story.permaLink) {
                logger.info("News story has permaLink [open as external link]")
                openExternalLink(story.permaLink)
            } else {
                logger.info("News story does not have permaLink [show in news details page]")
                showNewsDetailsModal(story)
            }
        },
        [openExternalLink, showNewsDetailsModal]
    )

    function handleLocationPress() {
        setIsLocationDialogOpen(true)
    }

    function offers() {
        if (takeawayOffers === null || takeawayOffers.length === 0) {
            return null
        }

        return (
            <LocalizedStrict id={"home-takeaway-offers"} attrs={{ title: true, rightOptionText: true }}>
                <ResourceCarousel
                    resources={takeawayOffers}
                    rightOptionText={"Se alle"}
                    rightOptionAction={onTakeawayOfferRightOptionClick}
                    title={"Gode tilbud"}
                    onCardClick={onTakeawayOfferClick}
                    resourceTitleProp={"name"}
                    resourceSubTitleProp={"kitchen.name"}
                    resourceImageProp={"imageUrl"}
                    resourcePriceProp={"price"}
                    headerTagTextProp={"headerTag"}
                    headerTagColorProp={"headerTagColor"}
                />
            </LocalizedStrict>
        )
    }

    function takeawayShops() {
        if (takeawayCanteens === null || takeawayCanteens.length === 0) return null

        return (
            <LocalizedStrict id="home-takeaway-shops" attrs={{ title: true }}>
                <Section title="Webshops">
                    <Grid container className={classes.list}>
                        {takeawayCanteens.map((resource: ICanteenDetails, key: number) => {
                            return (
                                <ResourceCard
                                    key={key}
                                    resource={resource}
                                    onClick={onTakeawayShopClick}
                                    headerTagTextProp=""
                                    resourceImageProp="brandingDetails.imageUrl"
                                    resourceTitleProp="name"
                                    resourceSubTitleProp="brandingDetails.description"
                                    updateProductCardUi
                                />
                            )
                        })}
                    </Grid>
                </Section>
            </LocalizedStrict>
        )
    }

    const newsSection = () => {
        return (
            <>
                {news.kitchenNews && news.kitchenNews.length > 0 && (
                    <LocalizedStrict id={"home-news-kitchen"} attrs={{ title: true, rightOptionText: true }}>
                        <ResourceCarousel
                            resources={news.kitchenNews}
                            rightOptionText={"Se alle"}
                            rightOptionAction={() => showAllNews("kitchenNews")}
                            title={"Nyt fra kantinen"}
                            onCardClick={onNewsStoryClick}
                            resourceTitleProp={"subject"}
                            resourceImageProp={"imageUrl"}
                            headerTagTextProp={""}
                            resourceSubTitleProp={"createdByCompany"}
                        />
                    </LocalizedStrict>
                )}
                {news.companyAndServiceCenterNews && news.companyAndServiceCenterNews.length > 0 && (
                    <LocalizedStrict
                        id={"home-news-company-and-service-center"}
                        attrs={{ title: true, rightOptionText: true }}
                        vars={{ company: news.companyAndServiceCenterNews[0].createdByCompany }}
                    >
                        <ResourceCarousel
                            resources={news.companyAndServiceCenterNews}
                            rightOptionText={"Se alle"}
                            rightOptionAction={() => showAllNews("companyAndServiceCenterNews")}
                            title={`Nyt fra ${news.companyAndServiceCenterNews[0].createdByCompany}`}
                            onCardClick={onNewsStoryClick}
                            resourceTitleProp={"subject"}
                            resourceImageProp={"imageUrl"}
                            headerTagTextProp={""}
                            resourceSubTitleProp={"createdByCompany"}
                        />
                    </LocalizedStrict>
                )}
                {news.facilityNetNews && news.facilityNetNews.length > 0 && (
                    <LocalizedStrict id={"home-news-facilitynet"} attrs={{ title: true, rightOptionText: true }}>
                        <ResourceCarousel
                            resources={news.facilityNetNews}
                            rightOptionText={"Se alle"}
                            rightOptionAction={() => showAllNews("facilityNetNews")}
                            title={"Nyt fra FacilityNet"}
                            onCardClick={onNewsStoryClick}
                            resourceTitleProp={"subject"}
                            resourceImageProp={"imageUrl"}
                            headerTagTextProp={""}
                            resourceSubTitleProp={"createdByCompany"}
                        />
                    </LocalizedStrict>
                )}
            </>
        )
    }

    const upcomingMeetingsSection = (resourceBookingEnabled: boolean) => {
        if (!resourceBookingEnabled || upcomingMeetings === null) {
            return <div />
        }

        if (upcomingMeetings.length === 0) {
            return (
                <div style={{ marginBottom: 30 }}>
                    <LocalizedStrict id="home-upcoming-meetings">
                        <Heading>Kommende møder</Heading>
                    </LocalizedStrict>
                    <LocalizedStrict id={"home-no-planned-meetings"}>
                        <Typography
                            gutterBottom={true}
                            variant="h5"
                            component="h3"
                            color="textSecondary"
                            className={classes.noPlannedMeetings}
                        >
                            Ingen planlagte møder
                        </Typography>
                    </LocalizedStrict>
                    <LocalizedStrict id={"home-plan-meeting"}>
                        <Link component="button" onClick={goToRoomFinder}>
                            Planlæg et møde
                        </Link>
                    </LocalizedStrict>
                </div>
            )
        }

        return (
            <LocalizedStrict id={"home-my-meetings"} attrs={{ title: true, rightOptionText: true }}>
                <Section title="Kommende møder" rightOptionAction={handleMyMeetings} rightOptionText="Vis alle">
                    <CardCarousel>
                        {upcomingMeetings.map((order: IOrder, key: number) => (
                            <UpcomingMeetingCard
                                key={key}
                                order={order}
                                showMeetingDetails={showMeetingDetails}
                                showMeetingOptions={showMeetingOptions}
                            />
                        ))}
                    </CardCarousel>
                </Section>
            </LocalizedStrict>
        )
    }

    const homeTopbar = () => {
        if (!myLocation) return

        return (
            <div className={classes.topBarContainer}>
                <AppBar>
                    <Toolbar className={classes.topBar} onClick={handleLocationPress}>
                        <SafeArea top>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                className={classes.locationLine}
                                wrap={"nowrap"}
                                spacing={1}
                            >
                                <Grid item>
                                    <FontAwesomeIcon
                                        icon={faMapMarkerAlt}
                                        color={theme.palette.grey[700]}
                                        className={classes.locationLineMapMarker}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    alignItems={"center"}
                                    justifyContent="flex-start"
                                    spacing={1}
                                    className={classes.locationLineLinkContainer}
                                    wrap={"nowrap"}
                                >
                                    <Grid item className={classes.locationName}>
                                        <Link
                                            component={Typography}
                                            variant="subtitle1"
                                            underline="none"
                                            className={classes.overflowEllipsis}
                                        >
                                            {myLocation}
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <FontAwesomeIcon icon={faChevronDown} size={"1x"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SafeArea>
                    </Toolbar>
                </AppBar>
            </div>
        )
    }

    return (
        <div>
            <Screen
                name="Home"
                showPrimaryTopBar={!myLocation}
                bottomElement={<BottomNavBar />}
                bottomElementHeight={WellknownHeight.BOTTOM_NAV_BAR}
                fitPage
            >
                {homeTopbar()}

                <ChooseLocationDialog isOpen={isLocationDialogOpen} onClose={() => setIsLocationDialogOpen(false)} />

                {!welcomeSection.loading &&
                    !welcomeSection.failed &&
                    welcomeSection.response &&
                    !isEmpty(welcomeSection.response.data) && (
                        <WelcomeSection
                            subjectKey={welcomeSection.response.data.subjectKey}
                            bodyKey={welcomeSection.response.data.bodyKey}
                            imageUrl={welcomeSection.response.data.backgroundImageUrl}
                        />
                    )}

                <UserCards page={UserCardPages.home} />

                {upcomingMeetingsSection(props.resourceBookingEnabled)}

                {offers()}

                {takeawayShops()}

                <ProductFavoritesCarousel
                    productFavorites={productFavorites}
                    onProductFavoriteRightOptionClick={onProductFavoriteRightOptionClick}
                    onProductFavoriteClick={onProductFavoriteClick}
                    handleRemoveFavorite={openRemoveFavoriteDialog}
                />

                <MenuOfTheWeekSection />

                {newsSection()}
            </Screen>

            <FeaturedProductModal
                open={featuredProductModal !== undefined}
                product={featuredProductModal}
                onClose={() => setFeaturedProductModal(undefined)}
                onDateClick={onFeaturedProductModalDateClick}
            />
            {takeawayOffers && (
                <ShowAllTakeawayOffersModal
                    open={displayAllTakeawayOffers}
                    onClose={() => setDisplayAllTakeawayOffers(false)}
                    onOfferClick={onTakeawayOfferClick}
                />
            )}
            {productFavorites && (
                <ShowAllProductFavoritesModal
                    open={displayAllFavorites}
                    onClose={() => setDisplayAllFavorites(false)}
                    onFavoriteClick={onProductFavoriteClick}
                />
            )}

            <LocalizedStrict id="home-product-favorites-options" attrs={{ loadingDescription: true }}>
                <OptionsDialog
                    open={selectedDialog?.type === SelectedDialog.SETTINGS}
                    onClose={handleCloseDialog}
                    loading={removingFavorite}
                    loadingDescription="Fjerner favorit..."
                >
                    <LocalizedStrict id="home-remove-favorite">
                        <ActionSheetButtonItem
                            color="error"
                            onClick={() => removeFromFavorite(selectedDialog?.productId)}
                        >
                            Fjern favorit
                        </ActionSheetButtonItem>
                    </LocalizedStrict>
                </OptionsDialog>
            </LocalizedStrict>
            <MyMeetingsDialog isOpen={isMyMeetingsOpen} onClose={handleMyMeetings} />
            <UserPrompts page="home" />
        </div>
    )
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({
    news,
    canteens,
    upcomingMeetings,
    user,
    login,
    takeawayOffers,
    takeawayCanteens,
    productFavorites,
    properties,
}) => ({
    takeawayCanteens: takeawayCanteens.canteens,
    canteens: canteens.locations,
    upcomingMeetings: upcomingMeetings.upcomingMeetings,
    news: news,
    myLocation: selectUserLocationName({ user }),
    userUid: login.loginStatus === LoginStatus.LOGGED_IN ? login.authentication?.userUid : undefined,
    takeawayOffers: takeawayOffers.offers,
    productFavorites: productFavorites.favorites,
    resourceBookingEnabled: properties.showResourceBookingModule,
})

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    showMeetingOptions: (order: IOrder) => {
        return dispatch(openMeetingOptionsModal(order))
    },
    showMeetingDetails: (order: IOrder) => {
        return dispatch(openMeetingDetailsModal(order))
    },
    showNewsDetailsModal: (news: INews) => {
        return dispatch(openNewsDetailsModal(news))
    },
    showAllNews: (category: keyof INewsState) => {
        return dispatch(openAllNewsModal(category))
    },
    goToRoomFinder: () => {
        return dispatch(setEditOrder(null, null))
    },
    setFoodOrderCartCache: (product: IFoodOrderProduct) => dispatch(setFoodOrderCartCache(product)),
    getProductFavorites: () => dispatch(getProductFavorites()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
