import { ApiResponse } from "shared/Types/responseTypes"
import ApiHelper from "shared/Helpers/ApiHelper"
import { http, getHeaders } from "shared/Helpers/http"
import { IWeeksMenu, IWeeksMenuLink } from "shared/Types/appTypes"

export const getWeeksMenu = (token: string, canteenId: number): Promise<ApiResponse<IWeeksMenu | IWeeksMenuLink>> => {
    // Call API
    return http(
        new Request(ApiHelper.path(`/canteen/` + canteenId + `/menues/weekMenu/week`), {
            method: "get",
            headers: getHeaders({
                token,
            }),
        })
    )
}
