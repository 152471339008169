import { SagaIterator } from "redux-saga"
import { put, take, call, select, all, fork } from "redux-saga/effects"
import { apiRequestSagaWithOptions, errorHandlerSagaCreator, errorLogger } from "shared/Helpers/SagaHelper"
import { CurrentEnvironment } from "shared/Modules/Environment/envTypes"
import { selectCurrentEnvironment } from "shared/Modules/Environment/envSelectors"
import { LoginActionTypes } from "shared/Modules/Login/loginActions"
import { selectApiToken } from "shared/Modules/Login/loginSelectors"
import { selectUserCanteenId } from "../User/userSelectors"
import { fetchMerchantConfig } from "./merchantConfigAPI"
import { IMerchantConfig } from "./merchantConfigTypes"
import { Logger } from "shared/Helpers/logging"
import {
    MerchantConfigActionTypes,
    getMerchantConfig as getMerchantConfigAction,
    setMerchantConfig,
} from "./merchantConfigActions"

const logger = new Logger("merchant-config")

export function* getMerchantConfigSaga(): SagaIterator {
    while (true) {
        yield take(MerchantConfigActionTypes.GET_MERCHANT_CONFIG)
        yield call(updateMerchantConfig)
    }
}

export function* updateMerchantConfig(isBackgroundRequest = false): SagaIterator {
    const token = yield select(selectApiToken)
    const userCanteenId: number | undefined = yield select(selectUserCanteenId)
    const environment: CurrentEnvironment = yield select(selectCurrentEnvironment)

    if (token && userCanteenId) {
        const responseData: IMerchantConfig | null = yield* apiRequestSagaWithOptions(
            fetchMerchantConfig,
            {
                isBackgroundRequest,
            },
            token,
            userCanteenId,
            environment
        )

        if (responseData) yield put(setMerchantConfig(responseData))
    }
}

export function* loadMerchantConfigOnLoginSaga(): SagaIterator {
    while (true) {
        yield take(LoginActionTypes.AUTHENTICATION_COMPLETE)
        logger.info("Detected user login: loading config")
        yield put(getMerchantConfigAction())
    }
}

export default function* moduleSaga(): SagaIterator {
    yield all([
        fork(errorHandlerSagaCreator, errorLogger, getMerchantConfigSaga),
        fork(errorHandlerSagaCreator, errorLogger, loadMerchantConfigOnLoginSaga),
    ])
}
