import React from "react"
import { Localized } from "@fluent/react"
import { makeStyles } from "@material-ui/core/styles"
import { Section } from "shared/Components/Skeleton/Section"
import { IFoodOrderProduct } from "shared/Modules/Product/productTypes"
import CardCarousel, { CardCarouselSize } from "mobile/Components/CardCarousel"
import HomeResourceCard from "./HomeResourceCard"

const useStyles = makeStyles(() => ({
    section: {
        marginBottom: "1.5rem",
    },
}))
interface IProps {
    productFavorites: IFoodOrderProduct[] | null
    onProductFavoriteRightOptionClick: () => any
    onProductFavoriteClick: (resource: any) => any
    handleRemoveFavorite: (e: any, id?: number) => void
}

function ProductFavoritesCarousel({
    productFavorites,
    onProductFavoriteRightOptionClick,
    onProductFavoriteClick,
    handleRemoveFavorite,
}: IProps) {
    const classes = useStyles()
    if (productFavorites === null || productFavorites.length === 0) return null

    return (
        <Localized id={"home-product-favorites"} attrs={{ title: true, rightOptionText: true }}>
            <Section
                title="Mine favoritter"
                rightOptionAction={onProductFavoriteRightOptionClick}
                rightOptionText="Se alle"
                className={classes.section}
            >
                <CardCarousel slideSize={CardCarouselSize.S}>
                    {productFavorites.map((resource: IFoodOrderProduct, key: number) => {
                        return (
                            <HomeResourceCard
                                key={key}
                                resource={resource}
                                onClick={onProductFavoriteClick}
                                headerTagTextProp="headerTag"
                                headerTagColorProp="headerTagColor"
                                resourceImageProp="imageUrl"
                                resourceTitleProp="name"
                                resourceSubTitleProp="kitchen.name"
                                onCardAction={handleRemoveFavorite}
                            />
                        )
                    })}
                </CardCarousel>
            </Section>
        </Localized>
    )
}

export default ProductFavoritesCarousel
