import React from "react"
import { connect, MapDispatchToPropsFunction } from "react-redux"
import { IconButton, Badge, Typography, makeStyles } from "@material-ui/core"
import { selectMerchantSettingsShowMenuConfig } from "shared/Modules/MerchantConfig/merchantConfigSelectors"
import { IOpenWeekMenuModal, openWeekMenuModal } from "mobile/Actions/weekMenuModalActions"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useExternalLinks } from "shared/Modules/Cordova/hooks/useAppLinks"
import { IMenu, IWeeksMenuLink } from "shared/Types/appTypes"
import { Section } from "shared/Components/Skeleton/Section"
import { IStoreState } from "../../../Reducers/rootReducer"
import menuOfTheWeek from "../assets/menuOfTheWeek.png"

export interface IOwnProps {}

interface IDispatchProps {
    showAllWeekMenu: () => IOpenWeekMenuModal
}
const useStyles = makeStyles((theme) => ({
    iconButton: {
        padding: 0,
        width: "100%",

        "& img": {
            width: "100%",
            height: "12.5rem",
            borderRadius: "0.5rem",
            objectFit: "cover",
        },
    },
    badge: {
        "& .MuiBadge-badge": {
            right: "3rem",
            top: "-3rem",
        },
    },
    badgeContent: {
        lineHeight: "normal",
        transform: "rotate(30deg)",
        backgroundColor: "#417076",
        color: "#fff",
        padding: "1rem",
        width: "5rem",
        height: "5rem",
        display: "flex",
        alignItems: "center",
        borderRadius: "50%",
        justifyContent: "center",
        fontWeight: 500,
    },
}))

interface MenuOfTheWeekSectionProps {
    menuOfTheWeekEnabled: boolean
    weeksMenu: { menues: IMenu[] | null } | IWeeksMenuLink
    showAllWeekMenu: () => IOpenWeekMenuModal
}

const MenuOfTheWeekSection = ({ menuOfTheWeekEnabled, weeksMenu, showAllWeekMenu }: MenuOfTheWeekSectionProps) => {
    const classes = useStyles()
    const openExternalLink = useExternalLinks()

    if (!menuOfTheWeekEnabled) {
        return null
    }

    const handleShowAllWeekMenu = () => {
        if ("externalMenuPlanning" in weeksMenu && weeksMenu.externalMenuPlanning?.url) {
            openExternalLink(weeksMenu.externalMenuPlanning.url)
        } else if ("menues" in weeksMenu) {
            showAllWeekMenu()
        }
    }

    return (
        <LocalizedStrict id="home-week-menu-title" attrs={{ title: true }}>
            <Section title="Week menu">
                <IconButton className={classes.iconButton} onClick={handleShowAllWeekMenu}>
                    <img src={menuOfTheWeek} alt="Week menu" />
                    <Badge
                        badgeContent={
                            <LocalizedStrict id="weekMenu-badge">
                                <Typography variant="subtitle1" className={classes.badgeContent}>
                                    Week menu
                                </Typography>
                            </LocalizedStrict>
                        }
                        className={classes.badge}
                    />
                </IconButton>
            </Section>
        </LocalizedStrict>
    )
}

const mapStateToProps = (state: IStoreState) => {
    const showMenuSetting = selectMerchantSettingsShowMenuConfig(state)

    return {
        menuOfTheWeekEnabled: showMenuSetting?.value ?? true,
        weeksMenu: state.weeksMenu,
    }
}
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    showAllWeekMenu: () => {
        return dispatch(openWeekMenuModal())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuOfTheWeekSection)
