import { IMerchantConfig, MerchantSettingsDetailsName } from "./merchantConfigTypes"

interface MerchantConfigState {
    merchantConfig: IMerchantConfig
}

export const selectMerchantSettingsShowMenuConfig = ({ merchantConfig }: MerchantConfigState) => {
    const setting = merchantConfig.config.merchantSettings?.find(
        (el) => el.name === MerchantSettingsDetailsName.SHOW_WEEK_MENU
    )

    if (setting) {
        return {
            ...setting,
            value: setting.value === "true",
        }
    }

    return undefined
}
